<template>
  <nav
    id="navbarBlur"
    class="navbar navbar-main navbar-expand-lg px-0 mx-4 border-radius-xl shadow-none left-auto top-2 z-index-sticky`"
    v-bind="$attrs"
    data-scroll="true"
  >
    <div class="px-0 py-1 container-fluid">
      <div
        id="navbar"
        class="mt-2 collapse navbar-collapse mt-sm-0 me-md-0 me-sm-4 me-sm-4 d-flex justify-content-between"
      >
        <div class="w-100 d-flex d-flex align-items-start left-warpper">
          <img src="../assets/18.webp" alt="" class="left-logo" />
        </div>
        <div class="w-100 d-flex justify-content-center">
          <img src="../assets/logo_new.webp" alt="" class="brand-logo" />
        </div>
        <div class="w-100 d-flex d-flex align-items-start left-warpper justify-content-end mt-1">
          <ul class="navbar-nav justify-content-end">
            <li class="nav-item d-flex align-items-center justify-content-end">
              
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
</template>
<script>
import { mapMutations, mapActions, mapGetters } from "vuex";

export default {
  name: "Navbar",
  data() {
    return {
      showMenu: false,
    };
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    currentDirectory() {
      let dir = this.$route.path.split("/")[1];
      return dir.charAt(0).toUpperCase() + dir.slice(1);
    },
    ...mapGetters(["Profile"]),
  },
  created() {
    this.minNav;
  },
  beforeUpdate() {
    this.toggleNavigationOnMobile();
  },
  methods: {
    ...mapMutations(["navbarMinimize", "toggleConfigurator"]),
    ...mapActions(["toggleSidebarColor"]),
    toggleNavigationOnMobile() {
      if (window.innerWidth < 1200) {
        this.navbarMinimize();
      }
    },
  },
};
</script>

<style scoped>
.serach-top {
  width: 300px;
}

.cart-navbar {
  display: none;
}

.brand-logo {
  width: auto;
  height: 110px;
}

.left-logo {
  width: auto;
  height: 50px;
}

.profile_pic {
  width: auto;
  height: 40px;
}

.left-warpper {
  height: 110px;
}

@media screen and (max-width: 300px) {
  .brand-logo {
    height: 75px;
  }

  .left-logo {
    height: 30px;
  }

  .profile_pic {
    height: 25px;
  }

  .left-warpper {
    height: 75px;
  }
}

@media screen and (min-width: 992px) {
  .cart-navbar {
    display: block;
  }
}
</style>
