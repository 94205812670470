<template>
    <div class="pb-4 container">
        <div class="row">
            <Boardcast />
        </div>
        <div class="row mb-4 justify-content-center">
            <div class="col-md-5">
                <div class="card cate-card">
                    <!-- Card header -->
                    <div class="p-0 px-1 pt-2 card-header">
                        <div class="text-center">
                            <div>
                                <div class="font-weight-bold text-dark">
                                    หมวดหมู่
                                </div>
                            </div>
                        </div>
                        <hr class="horizontal dark" />
                    </div>
                    <div
                        class="p-0 card-body mb-2 d-flex justify-content-center"
                        v-if="category.length > 0"
                    >
                        <select
                            class="form-control w-90"
                            @change="resetLoadingData()"
                            v-model="selectCategory"
                        >
                            <option value="all">ทั้งหมด</option>
                            <option value="sale">สินค้าลดราคา</option>
                            <option
                                v-for="(item, index) in category"
                                :key="index"
                                :value="item.name"
                            >
                                {{ item.name }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 col-12 mt-md-0 mt-3">
                <div class="card">
                    <!-- Card header -->
                    <div class="pb-0 card-header">
                        <div
                            class="d-md-flex justify-content-between align-items-center w-100"
                        >
                            <div class="text-center text-md-start">
                                <h5 class="mb-0">สินค้า</h5>
                            </div>
                            <div
                                class="w-lg-25 w-sm-50 w-100 d-flex align-items-center mt-3 mt-md-0"
                            >
                                <div class="input-group">
                                    <span class="input-group-text text-body">
                                        <i
                                            class="fas fa-search"
                                            aria-hidden="true"
                                        ></i>
                                    </span>
                                    <input
                                        type="text"
                                        class="form-control"
                                        placeholder="ค้นหา..."
                                        v-model="search"
                                    />
                                    <argon-button @click="submitSearch"
                                        >ค้นหา</argon-button
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="px-0 pb-0 card-body">
                        <div
                            class="row w-100 mx-auto g-3"
                            v-if="state === 'all'"
                        >
                            <div
                                class="col-lg-3 col-sm-4 col-6 position-relative"
                                v-for="item in products"
                                :key="item.id"
                            >
                                <router-link
                                    :to="`/product/${item.id}`"
                                    class="products_card card mb-3"
                                    :class="{ disabled: item.stock === 0 }"
                                >
                                    <div class="product-banner">
                                        <img src="../assets/new.png" :alt="item.name + ' New'" class="banner" v-if="item.new == 1">
                                        <img src="../assets/sale.png" :alt="item.name + ' Sale'" class="banner" v-if="item.sale == 1">
                                        <img src="../assets/hot.png" :alt="item.name + ' Hot'" class="banner" v-if="item.hot == 1">
                                        <img src="../assets/update.png" :alt="item.name + ' Update'" class="banner" v-if="item.p_update == 1">
                                    </div>
                                    <div class="h-100">
                                        <div
                                            class="card-header p-0 mx-3 mt-3 position-relative z-index-1"
                                        >
                                            <a class="d-block">
                                                <img
                                                    :src="imagePath + item.img"
                                                    class="border-radius-lg product_img"
                                                    :alt="item.name"
                                                    :style="[item.stock == 0 ? {'opacity': '0.3'} : '']"
                                                />
                                                <div class="soldout-text" :style="[item.stock == 0 ? {'display': 'flex !important'} : '']">
                                                    SOLD OUT
                                                </div>
                                            </a>
                                        </div>

                                        <div class="card-body pt-2" :style="[item.stock == 0 ? {'opacity': '0.3'} : '']">
                                            <span
                                                class="text-gradient text-primary cate-text font-weight-bold my-2"
                                                >{{ item.category }}</span
                                            >
                                            <a
                                                class="card-title text-sm d-block text-darker font-weight-bold product_name"
                                            >
                                                {{ item.name }}
                                            </a>
                                            <div
                                                class="text-sm text-danger" style="font-weight: 900;"
                                            >
                                                ฿{{
                                                    item.discount != 0
                                                        ? item.price -
                                                        item.discount
                                                        : item.price
                                                }}
                                                <span
                                                    class="text-xs ms-1"
                                                    style="opacity: 0.8"
                                                    v-if="item.discount != 0"
                                                    ><del
                                                        >฿{{ item.price }}</del
                                                    ></span
                                                >
                                            </div>
                                        </div>
                                    </div>
                                </router-link>
                            </div>
                            <InfiniteLoading
                                @infinite="load"
                                :identifier="reset"
                                class="text-center"
                            />
                        </div>
                        <div
                            class="row w-100 mx-auto g-3"
                            v-if="state === 'search'"
                        >
                            <div
                                class="col-lg-3 col-sm-4 col-6 position-relative"
                                v-for="item in productSearch"
                                :key="item.id"
                            >
                                <router-link
                                    :to="`/product/${item.id}`"
                                    class="products_card card mb-3"
                                    :class="{ disabled: item.stock === 0 }"
                                >
                                    <div class="overlay-soldout" :style="[item.stock == 0 ? {'display': 'block'} : '']">
                                        <div class="soldout-text">
                                            SOLD OUT
                                        </div>
                                    </div>
                                    <div class="product-banner">
                                        <img src="../assets/new.png" :alt="item.name + ' New'" class="banner" v-if="item.new == 1">
                                        <img src="../assets/sale.png" :alt="item.name + ' Sale'" class="banner" v-if="item.sale == 1">
                                        <img src="../assets/hot.png" :alt="item.name + ' Hot'" class="banner" v-if="item.hot == 1">
                                        <img src="../assets/update.png" :alt="item.name + ' Update'" class="banner" v-if="item.p_update == 1">
                                    </div>
                                    <div class="h-100">
                                        <div
                                            class="card-header p-0 mx-3 mt-3 position-relative z-index-1"
                                        >
                                            <a class="d-block">
                                                <img
                                                    :src="imagePath + item.img"
                                                    class="border-radius-lg product_img"
                                                    :alt="item.name"
                                                    :style="[item.stock == 0 ? {'opacity': '0.3'} : '']"
                                                />
                                                <div class="soldout-text" :style="[item.stock == 0 ? {'display': 'flex !important'} : '']">
                                                    SOLD OUT
                                                </div>
                                            </a>
                                        </div>

                                        <div class="card-body pt-2" :style="[item.stock == 0 ? {'opacity': '0.3'} : '']">
                                            <span
                                                class="text-gradient text-primary cate-text font-weight-bold my-2"
                                                >{{ item.category }}</span
                                            >
                                            <a
                                                class="card-title text-sm d-block text-darker font-weight-bold product_name"
                                            >
                                                {{ item.name }}
                                            </a>
                                            <div
                                                class="text-sm text-danger" style="font-weight: 900;"
                                            >
                                                ฿{{
                                                    item.discount != 0
                                                        ? item.price -
                                                        item.discount
                                                        : item.price
                                                }}
                                                <span
                                                    class="text-xs ms-1"
                                                    style="opacity: 0.8"
                                                    v-if="item.discount != 0"
                                                    ><del
                                                        >฿{{ item.price }}</del
                                                    ></span
                                                >
                                            </div>
                                        </div>
                                    </div>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "../services/axios";
import InfiniteLoading from "v3-infinite-loading";
import "v3-infinite-loading/lib/style.css";
import ArgonButton from "@/components/ArgonButton.vue";
import Boardcast from "./Boardcast.vue";

export default {
    name: "ProductsList",
    components: {
        InfiniteLoading,
        ArgonButton,
        Boardcast,
    },
    data() {
        return {
            headName: "สินค้าทั้งหมด",
            products: [],
            productSearch: [],
            category: [],
            selectCategory: "all",
            page: 0,
            npp: 20,
            search: "",
            state: "all",
            imagePath: process.env.VUE_APP_IMAGE_PATH,
            shop: "",
            reset: false,
        };
    },
    created() {
        let shop = localStorage.getItem("station");
        this.shop = shop == "deli" ? "loei" : shop;
        this.fetchCategory();
    },
    methods: {
        resetLoadingData() {
            this.state = "all";
            this.page = 0;
            this.reset = !this.reset;
            this.products = [];
        },
        submitSearch() {
            if (this.search) {
                axios
                    .get(`/search?search=${this.search}&shop=${this.shop}`)
                    .then((resp) => {
                        if (resp.data.status === "success") {
                            this.selectCategory = "";
                            this.search = "";
                            this.state = "search";
                            this.productSearch = resp.data.data;
                        }
                    })
                    .catch((err) => {
                        if (err?.response) {
                            if (err?.response?.status !== 500) {
                                this.$swal({
                                    text: err?.response?.data?.message
                                        ? err?.response?.data?.message
                                        : "ไม่สามารถโหลดข้อมูลสินค้าได้ กรุณาลองใหม่",
                                    icon: "error",
                                });
                            }
                        }
                    });
            } else {
                this.selectCategory = "all";
                this.state = "all";
            }
        },
        load($state) {
            axios
                .get(
                    `/products?category=${this.selectCategory}&page=${this.page}&npp=${this.npp}&shop=${this.shop}`
                )
                .then((resp) => {
                    if (resp.data.status === "success") {
                        if (resp.data.data.result.length < 1) $state.complete();
                        else {
                            this.products.push(...resp.data.data.result);
                            $state.loaded();
                            this.page = this.page + 1;
                        }
                    }
                })
                .catch((err) => {
                    if (err?.response) {
                        if (err?.response?.status !== 500) {
                            this.$swal({
                                text: err?.response?.data?.message
                                    ? err?.response?.data?.message
                                    : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่",
                                icon: "error",
                            });
                        }
                    }
                });
        },
        fetchCategory() {
            axios
                .get("/category")
                .then((resp) => {
                    if (resp.data.status === "success") {
                        this.category = resp.data.data;
                    }
                })
                .catch((err) => {
                    if (err?.response) {
                        if (err?.response?.status !== 500) {
                            this.$swal({
                                text: err?.response?.data?.message
                                    ? err?.response?.data?.message
                                    : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่",
                                icon: "error",
                            });
                        }
                    }
                });
        },
    },
};
</script>

<style scoped>
.soldout-text {
    position: absolute;
    align-items: center;
    justify-content: center;
    background-color: #161616;
    font-weight: bold;
    width: 105px;
    height: 35px;
    border-radius: 20px;
    top: 50%;
    left: 50%;
    font-size: 16px;
    color: white;
    transform: translate(-50%,-50%);
    z-index: 15;
    display: none !important;
}

a.disabled {
  pointer-events: none;
  cursor: default;
}

a:hover {
    color: inherit;
}
.product_name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.products_card:hover {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    transition: 1s;
    cursor: pointer;
}

.products_card .product_img {
    width: 100%;
    height: 120px;
    object-fit: cover;
}

.cate-text {
    font-size: 0.55em;
}

.product-banner {
    position: absolute;
    top: 2%;
    left: 6%;
    z-index: 2;
}

.banner {
    margin-right: 2px;
    width: auto;
    height: 30px;
}

@media only screen and (min-width: 420px) {
    .products_card .product_img {
        height: 170px;
    }

    .cate-text {
        font-size: 0.7em;
    }

    .banner {
        height: 30px;
    }
}

@media only screen and (min-width: 576px) {
    .products_card .product_img {
        height: 135px;
    }
    .cate-text {
        font-size: 0.65em;
    }
    .banner {
        height: 30px;
    }
}

@media only screen and (min-width: 768px) {
    .products_card .product_img {
        height: 145px;
    }
    .banner {
        height: 30px;
    }
}

@media only screen and (min-width: 992px) {
    .products_card .product_img {
        height: 155px;
    }

    .cate-text {
        font-size: 0.7em;
    }
    .banner {
        height: 30px;
    }
}

@media only screen and (min-width: 1200px) {
    .products_card .product_img {
        height: 165px;
    }

    .cate-text {
        font-size: 0.75em;
    }
    .banner {
        height: 30px;
    }
}

@media only screen and (min-width: 1400px) {
    .products_card .product_img {
        height: 210px;
    }

    .cate-text {
        font-size: 0.8em;
    }
    .banner {
        height: 35px;
    }
}

@media only screen and (min-width: 1700px) {
    .products_card .product_img {
        height: 250px;
    }
    .banner {
        height: 35px;
    }
}

@media only screen and (min-width: 1900px) {
    .products_card .product_img {
        height: 270px;
    }
    .banner {
        height: 35px;
    }
}

.btn-category {
    border: none;
    background-color: transparent;
    width: 100%;
    height: 35px;
}

.cate-card {
    height: auto;
}
</style>
