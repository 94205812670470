<template>
    <footer class="py-3 footer">
        <div class="container-fluid">
            <div class="row align-items-center justify-content-lg-between">
                <div class="mb-4 col-12">
                    <div class="text-sm text-center copyright text-muted">
                        Copyright ©
                        {{ new Date().getFullYear() }} 
                        <a class="font-weight-bold" target="_blank"
                            ><i class="fa fa-heart"></i> BBM</a
                        >
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: "AppFooter",
};
</script>

<style scoped>
footer {
    padding-bottom: 70px !important;
}
</style>
