<template>
    <router-view />
</template>

<script>
export default {
    name: "App",
    data() {
        return {};
    },
};
</script>

<style>
.full-loading {
  position: absolute;
  z-index: 1000;
  background-color: white;
  height: 100vh;
  width: 100vw;
  text-align: center;
  line-height: 100vh;
}
</style>
